.button-style {
  background-color: #ec9d4c; /* Orange background color */
  color: white; /* White text */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 20px; /* Rounded corners */
  font-size: 16px; /* Text size */
  cursor: pointer; /* Cursor changes to pointer to indicate it's clickable */
  outline: none; /* Remove the outline on focus */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.button-style:hover {
  background-color: #db8a3f; /* Darker shade of orange for hover effect */
}

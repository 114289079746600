/* Apply to both input and textarea */
input[type='text'],
input[type='email'],
textarea {
  width: 100%; /* Full width */
  padding: 10px; /* Spacing inside the input */
  margin: 8px 0; /* Margin for spacing outside the input */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: 1px solid #ccc; /* Border color */
  border-radius: 12px; /* Rounded corners */
  outline: none; /* Remove the outline */
  transition:
    border-color 0.3s,
    box-shadow 0.3s; /* Smooth transition for focus effects */
}

input[type='text']:focus,
input[type='email']:focus,
textarea:focus {
  border-color: #f59708; /* Focus border color - Deep purple */
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2); /* Focus shadow effect */
}

.button-style {
  background-color: #ec9d4c; /* Orange background color */
  color: white; /* White text */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 20px; /* Rounded corners */
  font-size: 16px; /* Text size */
  cursor: pointer; /* Cursor changes to pointer to indicate it's clickable */
  outline: none; /* Remove the outline on focus */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.button-style:hover {
  background-color: #db8a3f; /* Darker shade of orange for hover effect */
}

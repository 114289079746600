/* Content Tiles */
.ContentTiles {
  display: flex;
  flex-direction: column;
  gap: 80px; /* Space between tiles */
  padding: 32px 144px;
  box-sizing: border-box;
}

.tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 40px;
  padding: 32px;
  position: relative;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  gap: 32px;
  box-shadow: inset 0 0 0 4px #e3e3e3;
  box-sizing: border-box;
}

.background-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 4 / 3; /* Force aspect ratio */
  background-size: cover;
  background-position: center;
  border-radius: 40px;
  overflow: hidden;
}

.background-image-container img {
  width: 100%;
  height: auto;
}

.top-offset img {
  width: 75%;
  position: relative;
  top: 40%;
  left: 0;
}

.bottom-anchor img {
  width: 50%;
  position: absolute;
  bottom: 0;
}

.centered-image img {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fill img {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  width: 600px;
  flex-direction: column;
}

.title {
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
}

.description {
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.discord-button {
  display: inline-flex;
  align-items: center; 
  width: auto !important;
  max-width: max-content;
  background-color: #5865F2; /* Discord brand color */
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 32px;
  text-decoration: none;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.discord-button img {
  width: 20px; /* Match the height of the icon to what you need */
  height: auto; /* Ensure the height is set correctly */
  margin-right: 8px;
  flex-shrink: 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .ContentTiles {
    display: flex;
    flex-direction: column;
    gap: 80px; /* Space between tiles */
    padding: 16px 64px;
  }

  .tile {
    flex-direction: column;
    text-align: left;
    padding: 24px;
    gap: 16px;
  }

  .content {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    align-items: flex-start;
  }

  .tile-left {
    flex-direction: column-reverse; /* Image below content on wider screens for left-aligned tiles */
  }
  
  .tile-right {
    flex-direction: column; /* Image beside content for right-aligned tiles */
  }
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .ContentTiles {
    padding: 16px 64px;
  }
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .ContentTiles {
    gap: 64px;
    padding: 16px 32px;
  }

  .content {
    padding-left: 8px;
    padding-right: 8px;
  }
}
/* Hero Section */
.HeroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding-top: 64px;
  padding-left: 144px;
  padding-right: 144px;
  box-sizing: border-box;
}

.HeroSection-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 32px;
}

.HeroSection-text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.HeroSection-title {
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 1.2;
}

.HeroSection-body {
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.HeroSection-image {
  display: flex;
  width: 50%;
  height: auto;
  max-width: 600px; /* Adjust max-width as necessary */
}

.HeroSection-buttons {
  gap: 16px;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 24px;
  display: inline-flex;
  flex-wrap: wrap;
}

.HeroSection-button {
  background-color: #d48011;
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 32px;
  text-decoration: none;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .HeroSection {
    text-align: center;
    padding: 24px 80px;
    margin-top: 32px;
  }

  .HeroSection-content {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 40px;
  }

  .HeroSection-text {
    width: 100%;
    padding-right: 0;
    margin-top: 16px;
  }

  .HeroSection-image {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .HeroSection-buttons {
    margin-top: 16px;
  }
}


/* Mobile adjustments */
@media (max-width: 480px) {
  .HeroSection {
    padding: 16px 32px;
    margin-top: 16px;
  }

  .HeroSection-content {
    padding: 0 0;
  }

  .HeroSection-buttons {
    width: 100%;
    margin-top: 16px;
  }
}

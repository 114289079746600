.Panel-header {
  font-size: 40px;
}

.Panel-subheader {
  font-size: 24px;
}

.Panel-padding {
  padding-left: 300px !important;
  padding-right: 300px !important;
}

.Panel-paddingRight {
  padding-right: 300px !important;
}

.TopSection {
  position: relative;
  color: black;
  z-index: 1000;
}

.TopAppBar {
  background-color: white;
  color: black;
  padding: 12px 64px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centering vertically */
}

.TopAppBar-leftLogo {
  height: 40px;
}

.TopAppBar-navContainer {
  display: flex;
  height: 100%;
  gap: 8px;
  align-items: center; /* Centering vertically */
}

.TopBarNavButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px; /* Increased padding for spacing */
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.TopBarNavButton-selected {
  color: #d48011 !important;
}

.TopBarNavButton:hover {
  color: #d48011;
}

.TopNavDrawer {
  background-color: white;
  overflow: hidden;
  transition: height 0.3s ease;
}

.MaxColumnWidth {
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .TopAppBar {
    padding: 8px 32px;
  }

  .TopAppBar-navContainer {
    display: none; /* Hide nav container in mobile */
  }

  .TopNavDrawer {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .TopAppBar {
    padding: 8px 16px;
  }
}

/* Footer content start */

.footer-outer-container {
  background: linear-gradient(to bottom right, #2b2b2b, #161514);
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.footer-divider-container {
  display: flex;
  width: 100%;
}

.footer-divider-line {
  background-color: #595550;
  flex: 1;
  height: 4px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 72px 120px;  /* Vertical/horizontal padding */
}

.footer-social-icons {
  display: flex;
  justify-content: flex-start; /* Left align social icons */
  gap: 32px;
  padding-bottom: 16px; /* Reduced space below social icons */
}

.footer-social-icon-discord,
.footer-social-icon-twitter {
  height: 20px;
  object-fit: cover;
}

.top-footer-content {
  display: flex;
  justify-content: space-between;
  gap: 64px;
  align-items: start;
  padding: 0 16px;
}

.footer-link-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 64px;
}

.footer-link-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer-link-header {
  color: #ffffff;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
}

.footer-sublinks {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.footer-link {
  color: #ffffff;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-inner-divider {
  display: flex;
  width: 100%;
  margin: 16px 0;
  padding: 24px 0;
}

.footer-inner-divider-line {
  background-color: #595550;
  flex: 1;
  height: 1px;
}

.bottom-footer-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
}

.footer-logo {
  height: 32px;
  object-fit: cover;
}

.footer-download-buttons {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;
}

.footer-download-button {
  background-color: white;
  color: #D48011;
  padding: 12px 20px;
  border-radius: 32px;
  text-decoration: none;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.footer-download-button-text {
  color: #d48011;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 56px 80px;
  }

  .top-footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
  }

  .footer-download-buttons {
    justify-content: center;
  }

  .footer-social-icons {
    width: 100%;
    justify-content: flex-start;
  }

  .footer-link-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
  }

  .footer-link-group {
    text-align: left;
  }

  .bottom-footer-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 32px;
  }
}

/* Footer content end */

.DiscordBackgroundColor {
  background: #5b64e9;
}

.V2-ExpandableRow-arrow {
  transition: transform 0.3s ease;
  font-size: 1.5rem;
  transform: rotate(-45deg);
}

.V2-ExpandableRow-arrow.open {
  transform: rotate(90deg);
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  background-color: white;
  font-family: 'Lato', sans-serif;
  line-height: 1.7;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none; /* Removes the underline */
  color: #f59708; /* Sets the color to Artden orange */
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

input[type='text'],
input[type='email'],
textarea {
  background: white !important;
}

.FlexAlignCenter {
  align-items: center !important;
}

.Flex {
  display: flex;
}

.FlexHorizontalStart {
  justify-content: start !important;
}

.FlexJustifyCenter {
  justify-content: center !important;
}

.FlexSpaceBetween {
  justify-content: space-between !important;
}

.FlexWrap {
  flex-wrap: wrap;
}

.FlexWrapHalf {
  flex: 1 1 50%;
}

.MinWidth-350px {
  min-width: 350px;
}

.MaxWidth-350px {
  max-width: 350px;
}

.MaxWidth-500px {
  max-width: 500px;
}

.Underlined {
  border-bottom: 1px solid #ccc;
}

.GreyBorder {
  border: 1px solid #ccc;
}

.RedBorder {
  border: 1px solid red;
}

.BlueBorder {
  border: 1px solid red;
}

.FillWidth {
  width: 100%;
}

.FillHeight {
  height: 100%;
}

.CenterText {
  text-align: center;
}

.WhiteBackground {
  background-color: white;
}

.RedBackground {
  background-color: red;
}

.BlueBackground {
  background-color: blue;
}

.GreenBackground {
  background-color: greenyellow;
}

.LightGreyBackground {
  background-color: #f6f6f6;
}

.BorderSizing {
  box-sizing: border-box;
}

.Bold {
  font-weight: bold;
}

.Normal {
  font-weight: normal;
}

.BlackColor {
  color: black;
}

.WhiteColor {
  color: white;
}

.Clickable {
  cursor: pointer;
}

.Icon-1 {
  height: 20px;
  padding: 2px;
  box-sizing: border-box;
}

.FlexRow {
  display: flex;
  flex-direction: row;
}

.FlexColumn {
  display: flex;
  flex-direction: column;
}

.FlexEqualWeight {
  flex: 1;
}

.Gap-1 {
  gap: 0.25rem;
}
.Gap-2 {
  gap: 0.5rem;
}
.Gap-3 {
  gap: 0.75rem;
}
.Gap-4 {
  gap: 1rem;
}
.Gap-5 {
  gap: 1.25rem;
}
.Gap-6 {
  gap: 1.5rem;
}
.Gap-7 {
  gap: 1.75rem;
}
.Gap-8 {
  gap: 2rem;
}
.Gap-9 {
  gap: 2.25rem;
}
.Gap-10 {
  gap: 2.5rem;
}
.Gap-11 {
  gap: 2.75rem;
}
.Gap-12 {
  gap: 3rem;
}
.Gap-13 {
  gap: 3.25rem;
}
.Gap-14 {
  gap: 3.5rem;
}
.Gap-15 {
  gap: 3.75rem;
}
.Gap-16 {
  gap: 4rem;
}
.Gap-17 {
  gap: 4.25rem;
}
.Gap-18 {
  gap: 4.5rem;
}
.Gap-19 {
  gap: 4.75rem;
}
.Gap-20 {
  gap: 5rem;
}

.Margin-1 {
  margin: 0.25rem;
}
.Margin-2 {
  margin: 0.5rem;
}
.Margin-3 {
  margin: 0.75rem;
}
.Margin-4 {
  margin: 1rem;
}
.Margin-5 {
  margin: 1.25rem;
}
.Margin-6 {
  margin: 1.5rem;
}
.Margin-7 {
  margin: 1.75rem;
}
.Margin-8 {
  margin: 2rem;
}
.Margin-9 {
  margin: 2.25rem;
}
.Margin-10 {
  margin: 2.5rem;
}
.Margin-11 {
  margin: 2.75rem;
}
.Margin-12 {
  margin: 3rem;
}
.Margin-13 {
  margin: 3.25rem;
}
.Margin-14 {
  margin: 3.5rem;
}
.Margin-15 {
  margin: 3.75rem;
}
.Margin-16 {
  margin: 4rem;
}
.Margin-17 {
  margin: 4.25rem;
}
.Margin-18 {
  margin: 4.5rem;
}
.Margin-19 {
  margin: 4.75rem;
}
.Margin-20 {
  margin: 5rem;
}
.Margin-Vertical-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.Margin-Vertical-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.Margin-Bottom-4 {
  margin-bottom: 1rem;
}

.Padding-1 {
  padding: 0.25rem;
}
.Padding-2 {
  padding: 0.5rem;
}
.Padding-3 {
  padding: 0.75rem;
}
.Padding-4 {
  padding: 1rem;
}
.Padding-5 {
  padding: 1.25rem;
}
.Padding-6 {
  padding: 1.5rem;
}
.Padding-7 {
  padding: 1.75rem;
}
.Padding-8 {
  padding: 2rem;
}
.Padding-9 {
  padding: 2.25rem;
}
.Padding-10 {
  padding: 2.5rem;
}
.Padding-11 {
  padding: 2.75rem;
}
.Padding-12 {
  padding: 3rem;
}
.Padding-13 {
  padding: 3.25rem;
}
.Padding-14 {
  padding: 3.5rem;
}
.Padding-15 {
  padding: 3.75rem;
}
.Padding-16 {
  padding: 4rem;
}
.Padding-17 {
  padding: 4.25rem;
}
.Padding-18 {
  padding: 4.5rem;
}
.Padding-19 {
  padding: 4.75rem;
}
.Padding-20 {
  padding: 5rem;
}
.Padding-Left-8 {
  padding-left: 2rem;
}
.Padding-Left-10 {
  padding-left: 2.5rem;
}
.Padding-Right-10 {
  padding-right: 2.5rem;
}
.Padding-Vertical-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.Padding-Vertical-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.Padding-Horizontal-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.Padding-Horizontal-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.Padding-Horizontal-18 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}
.Padding-Bottom-4 {
  padding-bottom: 1rem;
}
.Padding-Bottom-10 {
  padding-bottom: 2.5rem;
}
.Size-1 {
  height: 0.25rem;
  width: 0.25rem;
}
.Size-2 {
  height: 0.5rem;
  width: 0.5rem;
}
.Size-3 {
  height: 0.75rem;
  width: 0.75rem;
}
.Size-4 {
  height: 1rem;
  width: 1rem;
}
.Size-5 {
  height: 1.25rem;
  width: 1.25rem;
}
.Size-6 {
  height: 1.5rem;
  width: 1.5rem;
}
.Size-7 {
  height: 1.75rem;
  width: 1.75rem;
}
.Size-8 {
  height: 2rem;
  width: 2rem;
}
.Size-9 {
  height: 2.25rem;
  width: 2.25rem;
}
.Size-10 {
  height: 2.5rem;
  width: 2.5rem;
}
.Size-11 {
  height: 2.75rem;
  width: 2.75rem;
}
.Size-12 {
  height: 3rem;
  width: 3rem;
}
.Size-13 {
  height: 3.25rem;
  width: 3.25rem;
}
.Size-14 {
  height: 3.5rem;
  width: 3.5rem;
}
.Size-15 {
  height: 3.75rem;
  width: 3.75rem;
}
.Size-16 {
  height: 4rem;
  width: 4rem;
}
.Size-17 {
  height: 4.25rem;
  width: 4.25rem;
}
.Size-18 {
  height: 4.5rem;
  width: 4.5rem;
}
.Size-19 {
  height: 4.75rem;
  width: 4.75rem;
}
.Size-20 {
  height: 5rem;
  width: 5rem;
}
.Size-40 {
  height: 10rem;
  width: 10rem;
}
.Width-20 {
  width: 5rem;
}
.Width-40 {
  width: 10rem;
}
.TextSize-Header {
  font-size: 2rem;
  line-height: 1.2;
}
.TextSize-Body {
  font-size: 1rem;
}
.TextSize-Caption {
  font-size: 0.75rem;
}
.TextSize-SectionHeader {
  font-size: 1.5rem;
  line-height: 1.2;
}

.Shared-ExpandableRow {
  width: 100%;
}

.Shared-ExpandableRow-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.Shared-ExpandableRow-arrow {
  transition: transform 0.3s ease;
  font-size: 2rem;
}

.Shared-ExpandableRow-arrow.open {
  transform: rotate(90deg);
}

.Shared-ExpandableRow-content {
  overflow: hidden;
  transition: height 0.3s ease;
}

.Shared-LoadingSpinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.RoundedCorners-1 {
  border-radius: 0.5rem;
}

.RoundedCorners-2 {
  border-radius: 1rem;
}

.bottom-content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap; /* Enable wrapping on smaller screens */
}

.empty-column {
  flex: 1; /* Take up equal space as other columns */
  height: 0; /* Don't render any content in this column */
}

.bottom-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1; /* Allow it to take up available space */
}

.bottom-content {
  flex: 1; /* Take up equal space as other columns */
  padding-top: 16px;
  text-align: center;
}

.bottom-explorer-container {
  flex: 1; /* Take up equal space as other columns */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transform: translateX(-20%); /* Shift the container 20% to the left */
}

.bottom-explorer-image {
  max-width: 184px;
  height: auto;
}

.BottomContent h2 {
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px; /* Reduce space between title and buttons */
}

.bottom-buttons {
  display: inline-flex; /* Inline-flex to allow wrapping based on content */
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping of buttons only if necessary */
  margin-bottom: 32px; /* Add margin below the buttons */
}

.BottomContent-button {
  background-color: #D48011;
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 32px;
  text-decoration: none;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
  width: auto; /* Auto width to wrap content */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
  text-align: center; /* Center text within the button */
}

.footer-art-credit {
  text-align: center;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  align-items: flex-end;
}

.footer-art-credit a {
  color: #D48011; /* Color for the artist's link */
  text-decoration: none;
}

.footer-art-credit a:hover {
  text-decoration: underline;
}

/* Mobile adjustments */
@media (max-width: 1024px) {
  .bottom-content-container {
    flex-direction: column; /* Stack everything vertically on small screens */
    align-items: center; /* Center items in column layout */
    padding-bottom: 0; /* Remove bottom padding for small screens */
  }

  .bottom-explorer-container {
    position: relative;
    margin-bottom: 0;
    right: auto;
    bottom: auto;
    justify-content: center;
    transform: none;
  }
}
